<template>
    <div class="group-bg-box">
        <div class="newword-security-box">
            <div class="network-security-pc-titles w-33p" v-for="(item,index) in items" :key="index"  @click="itemsClick(index)">
                {{item.name}}
                <div class="network-security-pc-titles-active" v-if="index == itemsActive"></div>
            </div>
        </div>

        <div v-show="itemsActive == 0">
            <div class="about-box d-flex d-flex-between">
                <div class="about-item-box w-40p">
                    <div class="about-item-group">量身定制开发</div>
                    <div class="about-item-swipers p-25">
                        <img src="@/assets/image/company/network-security/bg.png" class="w-100p">
                    </div>
                </div>
                <div class="about-item-box w-58p">
                    <div class="about-item-group-title">
                        量身定制开发
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="about-item-group-desc">
                        <div>
                            可根据客户的需求，量身定制一系列符合客户实际应用的软件。 目前的多数信息管理软件可以满足行业内的通用需求,而在特殊流程和客户定制.上成本很高。 对于中小企业来说,可以根据自己的实际业务需求,定制开发一-套适合自己的软件。 开发不求一步到位，一步-步进行完善。如此，可以减少投入,并非常适合自身业务发展。 软件定制主要是指企业管理软件的定制,企业管理软件是指能够体现企业管理的大部分职能(包括决策、计划、组织、领导、 监控、分析等等) ,能够提供实时、相关、准确、完整的数据,为管理者提供决策依据的一种软件。以模块划分,企业管理软件可分为财务管理进销存管理(ERP)、人力资源管理(HR)、供应链管理( SCM)、客户关系管理( CRM )等品种。
                        </div>
                    </div>
                </div>
            </div>
            <div class="about-box m-t-20">
                <div class="w-100p">
                    <div class="about-item-group-title">
                        我们的定制开发服务
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="p-20 d-flex d-flex-around">
                        <div class="newword-security-characteristic-pc-box">
                            <img src="@/assets/image/company/network-security/1-1.png" class="w-40p m-l-30p m-t-10p m-b-10p">
                            <div class="w-80p m-l-10p color-white p-b-50">
                                <div class="align-center p-b-45 f-26">app定制开发</div>
                                <div class="f-14">Andriod/Ios源生APP开发，跨平台混合APP开发，自主研发高性能框架。</div>
                            </div>
                        </div>
                        <div class="newword-security-characteristic-pc-box">
                            <img src="@/assets/image/company/network-security/1-2.png" class="w-40p m-l-30p m-t-10p m-b-10p">
                            <div class="w-80p m-l-10p color-white p-b-50">
                                <div class="align-center p-b-45 f-26">微信小程序定制开发</div>
                                <div class="f-14">为电商、新零售、智能硬件等各行业提供一站</div>
                            </div>
                        </div>
                        <div class="newword-security-characteristic-pc-box">
                            <img src="@/assets/image/company/network-security/1-3.png" class="w-40p m-l-30p m-t-10p m-b-10p">
                            <div class="w-80p m-l-10p color-white p-b-50">
                                <div class="align-center p-b-45 f-26">pc端网站定制开发</div>
                                <div class="f-14">丰富的系统架构经验提供高并发、高可靠性。</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="about-box m-t-20">
                <div class="w-100p">
                    <div class="about-item-group-title">
                        定制开发服务优势特点
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="d-flex">
                        <div class="network-security-yx-box p-b-5p">
                            <div class="network-security-yx-header">量身定制开发特点</div>
                            <div class="align-center">
                                <div class="network-security-yx-title1 m-t-2p">[量身定制培训方案]</div>
                                <div class="network-security-yx-title2 m-t-10 m-b-2p w-40p m-l-30p">专业团队按企业需求定制技术学习的路径以及合作方案，对企业人才进行一站体系化教学培训，全方面完成能力优化和提升</div>
                            </div>
                            <img src="@/assets/image/company/network-security/ys.png" class="w-40p m-l-30p ">
                            <div class="d-flex d-flex-around p-b-50">
                                <div class="align-center w-50p">
                                    <div class="network-security-yx-title1 m-t-2p">[互联网安全行业专家]</div>
                                    <div class="network-security-yx-title2 m-t-10 m-b-2p w-80p m-l-10p">专业团队按企业需求定制技术学习的路径以及合作方案，对企业人才进行一站体系化教学培训，全方面完成能力优化和提升</div>
                                </div>
                                <div class="align-center  w-50p">
                                    <div class="network-security-yx-title1 m-t-2p">[专业客户服务团队]</div>
                                    <div class="network-security-yx-title2 m-t-10 m-b-2p  w-80p m-l-10p">专业团队按企业需求定制技术学习的路径以及合作方案，对企业人才进行一站体系化教学培训，全方面完成能力优化和提升</div>
                                </div>
                            </div>
                        </div>
                        <div class="network-security-yx-box p-b-5p">
                            <div class="network-security-yx-header">量身定制开发优势</div>
                            <div class="align-center">
                                <div class="network-security-yx-title1 m-t-2p">[量身定制培训方案]</div>
                                <div class="network-security-yx-title2 m-t-10 m-b-2p w-40p m-l-30p">专业团队按企业需求定制技术学习的路径以及合作方案，对企业人才进行一站体系化教学培训，全方面完成能力优化和提升</div>
                            </div>
                            <img src="@/assets/image/company/network-security/ys.png" class="w-40p m-l-30p ">
                            <div class="d-flex d-flex-around p-b-50">
                                <div class="align-center w-50p">
                                    <div class="network-security-yx-title1 m-t-2p">[互联网安全行业专家]</div>
                                    <div class="network-security-yx-title2 m-t-10 m-b-2p w-80p m-l-10p">专业团队按企业需求定制技术学习的路径以及合作方案，对企业人才进行一站体系化教学培训，全方面完成能力优化和提升</div>
                                </div>
                                <div class="align-center  w-50p">
                                    <div class="network-security-yx-title1 m-t-2p">[专业客户服务团队]</div>
                                    <div class="network-security-yx-title2 m-t-10 m-b-2p  w-80p m-l-10p">专业团队按企业需求定制技术学习的路径以及合作方案，对企业人才进行一站体系化教学培训，全方面完成能力优化和提升</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="about-box m-t-20">
                <div class="w-100p">
                    <div class="about-item-group-title">
                        定制开发服务优势特点
                        <div class="about-item-group-dir"></div>
                    </div>
                    <img src="@/assets/image/company/network-security/bg3.png" class="w-90p m-l-5p">
                </div>
            </div>
        </div>
        <!-- 1 -->
        <div v-show="itemsActive == 1">
            <div class="about-box d-flex d-flex-between">
                <div class="about-item-box w-40p">
                    <div class="about-item-group">速成建站</div>
                    <div class="about-item-swipers p-25">
                        <img src="@/assets/image/company/network-security/bg2.png" class="w-100p">
                    </div>
                </div>
                <div class="about-item-box w-58p">
                    <div class="about-item-group-title">
                        速成建站
                        <div class="about-item-group-dir"></div>
                    </div>
                    <div class="about-item-group-desc">
                        <div>
                            是指企业管理软件的定制,企业管理软件是指能够体现企业管理的大部分职能(包括决策、计划、组织、领导、 监控、分析等等) ,能够提供实时、相关、准确、完整的数据,为管理者提供决策依据的一种软件。以模块划分,企业管理软件可分为财务管理进销存管理(ERP)、人力资源管理(HR)、供应链管理( SCM)、客户关系管理( CRM )等品种。
                        </div>
                    </div>
                </div>
            </div>
            <div class="about-box m-t-20">
                <div class="w-100p">
                    <div class="about-item-group-title">
                        定制开发服务优势特点
                        <div class="about-item-group-dir"></div>
                    </div>
                    <img src="@/assets/image/company/network-security/bg4.png" class="w-90p m-l-5p">
                </div>
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent,ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
    setup(){
        const itemsActive:any = ref(0)
        const items:any = ref([
            { name:"量身定制开发" },
            { name:"速成建站" },
        ])
        const itemsClick = (key:any)=>{
            itemsActive.value = key
        }
        return{
            items,itemsActive,itemsClick
        }
    }
});
</script>


<style>
.el-carousel__item--card.is-in-stage.is-hover .el-carousel__mask, .el-carousel__item--card.is-in-stage:hover .el-carousel__mask {
    opacity: 1 !important;
}
.el-carousel__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
     background:none !important;
    opacity: .24;
    transition: var(--el-transition-duration-fast);
}
</style>
